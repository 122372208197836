import { callMindbazApi } from "../../services/http/callApi";
import getTemplatesPanel from "../TemplatesPanel/TemplatesPanel";

export function initializeGrape(editor, idCampaign, idSite, setLoading, callBeginnerTutorial, tokenContext) {
  if (editor) {
    configButtons(editor, callBeginnerTutorial);
    addAssetsListener(editor, idSite, idCampaign, setLoading, tokenContext);
    editComponents(editor);
  }
}

/**
 * * Function that add options / redo / undo buttons
 * @param {Editor} editor
 */

function configButtons(editor, callBeginnerTutorial) {
  const { Panels } = editor;

  const views = "views";
  const commands = "commands";
  const options = "options";

  const settings = "open-tm";
  const styleManager = "open-sm";
  const blocks = "open-blocks";
  const layers = "open-layers";
  const undo = "undo";
  const redo = "redo";

  const settingsButton = Panels.getButton(views, settings);
  const styleManagerButton = Panels.getButton(views, styleManager);
  const blocksButton = Panels.getButton(views, blocks);
  const undoButton = Panels.getButton(commands, undo);
  const redoButton = Panels.getButton(commands, redo);

  Panels.removeButton(views, settings);
  Panels.removeButton(views, styleManager);
  Panels.removeButton(views, layers);
  Panels.removeButton(views, blocks);
  Panels.removeButton(commands, undo);
  Panels.removeButton(commands, redo);

  // prevent newsletter plugin error
  Panels.addButton(views, [
    {
      id: layers,
      className: "custom-hidden",
    },
  ]);

  let templatesPanel;

  Panels.addButton(views, {
    id: "templates",
    attributes: { class: "fa fa-columns", title: "Templates" },
    command: {
      run: (editor) => {
        if (templatesPanel) {
          templatesPanel.style.display = "block";
        } else {
          templatesPanel = getTemplatesPanel(editor);
          Panels.getPanel(views).set("appendContent", templatesPanel);
        }
      },
      stop: () => {
        if (templatesPanel) templatesPanel.style.display = "none";
      },
    },
  });

  Panels.addButton(views, blocksButton);
  Panels.addButton(views, styleManagerButton).set("active", false);
  Panels.addButton(views, settingsButton);
  Panels.addButton(options, undoButton);
  Panels.addButton(options, redoButton);
  Panels.addButton(options, {
    id: "tuto",
    attributes: { class: "fa fa-question-circle", title: "View tutorial" },
    command: {
      run: (editor) => {
        callBeginnerTutorial(true);
      },
    },
  });
}

function addAssetsListener(editor, idSite, idCampaign, setLoading, tokenContext) {
  editor.on("asset:upload:start", () => setLoading(true));

  editor.on("asset:upload:end", () => setLoading(false));

  editor.on("run:open-assets:before", () => {
    const { refreshToken, endpoint, appId, redirectUrl } = tokenContext;

    refreshToken(endpoint, appId, redirectUrl).then((token) => {
      editor.AssetManager.getConfig().headers.Authorization = "Bearer " + token;
    });
  });

  // apply style on uniq id
  editor.on("selector:add", (selector) => selector.set("private", 1));

  editor.on("asset:upload:response", (response) => {
    if (response?.success && response.data) {
      const { data } = response;
      data["src"] = data.url.replace(/\\/g, "/");

      const assetManager = editor.AssetManager;
      if (!assetManager.get(data["src"])) assetManager.add(data);
    }
  });

  editor.on("asset:upload:error", (response) => {
    const json = JSON.parse(response);
    const fileExceedMessage = "Error, file size limit exceeded";
    const { error } = json;

    if (error && error.includes(fileExceedMessage)) {
      alert(`${fileExceedMessage} (max: 800ko)`);
    } else if (error) {
      alert(error);
    } else alert(response);
  });

  editor.on("asset:remove", (event) => {
    const idImage = event.attributes.idPicture;

    callMindbazApi(`${idSite}/pictures/${idImage}`, {
      method: "DELETE",
    });
  });

  editor.on("canvas:dragdata", (dataTransfer, { content }) => {
    if (dataTransfer.effectAllowed === "copyLink" && content.length > 0) {
      content.forEach(({ attributes, file }) => {
        attributes["src"] = `https://mindstatic.mindbaz.com/${idSite}/camp_${idCampaign}/${file.name}`;
      });
    }
  });
}

function editComponents({ DomComponents: components }) {
  const { model, view } = components.getType("text");

  // enable td editable cell
  components.addType("cell", {
    model: model.extend(
      {
        defaults: {
          ...model.prototype.defaults,
          type: "cell",
          tagName: "td",
          draggable: ["tr"],
          droppable: true,
        },
      },
      {
        isComponent({ tagName }) {
          if (tagName === "TD" || tagName === "TH") {
            return {
              type: "cell",
              tagName: tagName.toLowerCase(),
            };
          }
        },
      }
    ),
    view,
  });
}
