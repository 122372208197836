import React from "react";
import { callMindbazApi } from "../services/http/callApi";

export const useFetchCampaign = (idSite, idCampaign, shouldExecute = true) => {
  const [loading, setLoading] = React.useState(true);
  const [value, setValue] = React.useState();

  React.useEffect(() => {
    if (idSite && idCampaign && shouldExecute) {
      setLoading(true);
      callMindbazApi(`${idSite}/campaigns/${idCampaign}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((result) => {
          setValue(result.data.data);
          setLoading(false);
        })
        .catch(function (error) {
          //Handle error
          setLoading(false);
        });
    }
  }, [idSite, idCampaign, shouldExecute]);

  return { loading, value };
};
