import React from "react";
import { useTranslation } from "react-i18next";

import "./App.css";
import Editor from "./components/Editor/Editor";
import { useFetchCampaign } from "./hooks/useFetchCampain";

export const InfoCtx = React.createContext(null);

function App() {
  const { t } = useTranslation();
  const [campaignData, setCampaignData] = React.useState(undefined);

  const query = new URLSearchParams(window.location.search);

  const info = {
    idSite: query.get("siteId"),
    idCampaign: query.get("campaignId"),
    siteOrigin: decodeURIComponent(query.get("origin")),
  };

  const { loading: isCampaignLoading, value: resultCampaignData } = useFetchCampaign(
    info.idSite,
    info.idCampaign,
    campaignData === undefined
  );

  if (!isCampaignLoading && resultCampaignData && campaignData === undefined) {
    setCampaignData(resultCampaignData);
  }

  if (info.idSite === null || info.idCampaign === null) {
    window.confirm(t("modal.error.select_campaign"));
    window.location.href = process.env.REACT_APP_MINDBAZ_URL;
    return;
  }

  return (
    <div className="App">
      <InfoCtx.Provider value={info}>
        <Editor campaignData={campaignData} isCampaignLoading={isCampaignLoading} />
      </InfoCtx.Provider>
    </div>
  );
}

export default App;
