import React from "react";
import styled from "styled-components";

const SpinnerContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
}`;

const Spinner = styled.span`
  animation: spin 2s linear infinite;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 80px;
  height: 80px;
  position: fixed;
  left: calc(50% - 40px);
  top: calc(50% - 40px);
  z-index: 1001;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Loader = () => (
  <SpinnerContainer>
    <Spinner />
  </SpinnerContainer>
);

export default Loader;
