export default `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">

<head>
	<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
	<title>Fullbaz - Base template</title>
	<style>
		html { background-color: #F4F4F4; margin: 0; padding: 0; }
	body, .selectors-mainTable, .selectors-mainTd { height: 100% !important; margin: 0; padding: 0; width: 100% !important; font-family: Arial, Helvetica, sans-serif; }
	table, table td { border-collapse: collapse; }
	table[class="mainTable"] { width: 100% !important; margin: auto; max-width: 640px !important; color: #666666; }
	img, a img { border: 0; outline: none; text-decoration: none; height: auto; line-height: 100%; }
	.ReadMsgBody { width: 100%; }
	.ExternalClass { width: 100%; }
	.ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div { line-height: 100%; }
	table, td { mso-table-lspace: 0pt; mso-table-rspace: 0pt; }
	#outlook a { padding: 0; }
	img { -ms-interpolation-mode: bicubic; display: block; outline: none; text-decoration: none; }
	body, table, td, p, a, li, blockquote { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }
	@media only screen and (max-width: 480px) { body { width: 100% !important; min-width: 100% !important; }
	  table[class="fluidContainer"] { width: 100% !important; }
	  table[class="fluidHalfContainer"] { width: 50% !important; }
	  img[class="fluidImg"] { width: 100% !important; height: auto !important; }
	  *[class="mbzShowMobile"] { max-height: none !important; max-width: none !important; opacity: 1 !important; overflow: auto !important; }
	  table[class="mbzShowMobile"] { display: table !important; }
	  td[class="mbzShowMobile"] { display: table-cell !important; } }
	</style>
</head>

<body bgcolor="#F4F4F4" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
	<!-- center layout -->
	<center style="background-color:#F4F4F4;">

		<!-- START main table -->
		<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" class="mainTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
			<tr>
				<!-- START main td -->
				<td align="center" valign="top" class="mainTd" style="padding: 30px 0 30px 0;">

					<!-- START HEADER -->
					<table bgcolor="#F4F4F4" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col -->
								<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
									<tr>
										<td style="padding: 0 30px 15px 30px;">
											<!-- START header txt -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;font-size:12px;line-height:135%;color:#999999;text-align:center;">
														Sed ut perspiciatis unde omnis iste <a href="#" style="color:#999999;">natus error</a>
													</td>
												</tr>
											</table>
											<!-- //END header txt -->
										</td>
									</tr>
								</table>
								<!-- //END col -->
							</td>
						</tr>
					</table>
					<!-- //END HEADER -->

					<!-- START wrapper -->
					<table bgcolor="#E03627" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="320" align="left" class="fluidContainer">
									<tr>
										<td style="padding: 10px 20px">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<tr>
													<td>
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/logo1.jpg" alt="Your company logo">
													</td>
												</tr>
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
								<!--[if gte mso 9]></td><td><![endif]-->
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="320" align="right" class="fluidContainer">
									<tr>
										<td style="padding: 25px 20px">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!-- start menu right -->
												<tr>
													<td>
														<table width="100%" border="0" cellspacing="0" cellpadding="0">
															<tr>
																<td>
																	<table border="0" cellspacing="0" cellpadding="0" align="right">
																		<tr>
																			<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 5px;font-size:13px;line-height:135%;color:#ffffff;" align="center">
																				<a href="#link" style="color:#ffffff;text-decoration: none;">Home</a>
																			</td>
																			<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 10px;font-size:16px;line-height:135%;color:#ffffff;" align="center">|</td>
																			<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 5px;font-size:13px;line-height:135%;color:#ffffff;" align="center">
																				<a href="#link" style="color:#ffffff;text-decoration: none;">About us</a>
																			</td>
																			<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 10px;font-size:16px;line-height:135%;color:#ffffff;" align="center">|</td>
																			<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 5px;font-size:13px;line-height:135%;color:#ffffff;" align="center">
																				<a href="#link" style="color:#ffffff;text-decoration: none;">Contact us</a>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
													</td>
												</tr>
												<!-- //END menu right -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START wrapper -->
					<table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="100%" align="right" class="fluidContainer">
									<tr>
										<td style="padding: 0 0 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!--start img-->
												<tr>
													<td style="padding: 0;">
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/welcome.jpg" width="640" height="418" border="0" style="display: block;" class="fluidImg" alt="sit amet">
													</td>
												</tr>
												<!-- //END img -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START wrapper -->
					<table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="100%" align="right" class="fluidContainer">
									<tr>
										<td style="padding: 0 30px 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!--start Title -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 5px;font-size:28px;font-weight:bold;line-height:135%;color:#E03627;text-align:center;">
														Sed do tempor labore
													</td>
												</tr>
												<!-- start paragraph -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:15px;line-height:135%;color:#666666;text-align:center;">
														Lorem ipsum dolor sit amet, consectetur adipiscing elit.
													</td>
												</tr>
												<!-- //END paragraph -->
												<!-- //END Title -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START wrapper -->
					<table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="320" align="left" class="fluidContainer">
									<tr>
										<td style="padding: 0 30px 30px 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!--start img-->
												<tr>
													<td style="padding: 0 0 15px 0;">
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/260-1.jpg" width="260" height="170" border="0" style="display: block;" class="fluidImg" alt="Lorem ipsum dolor">
													</td>
												</tr>
												<!-- //END img -->
												<!-- start heading "h2 like" -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:20px;font-weight:bold;line-height:135%;color:#333333;text-align:center;">
														Sed ut perspiciatis
													</td>
												</tr>
												<!-- //END heading "h2 like" -->
												<!-- start paragraph -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:15px;line-height:135%;color:#666666;text-align:center;">
														Lorem ipsum dolor sit amet, consectetur adipiscing elit.
													</td>
												</tr>
												<!-- //END paragraph -->
												<!-- start button default -->
												<tr>
													<td>
														<table width="100%" border="0" cellspacing="0" cellpadding="0">
															<tr>
																<td>
																	<table border="0" cellspacing="0" cellpadding="0" align="center">
																		<tr>
																			<td bgcolor="#E03627" style="padding:10px 20px 10px 20px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px" align="center"><a href="http://mail.mindbaz.com" target="_blank" style="font-size:15px;font-family:Arial, Helvetica, sans-serif;font-weight:normal;color:#FFFFFF;text-decoration:none;display:block;">Perspiciatis</a>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
													</td>
												</tr>
												<!-- //END button default -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
								<!--[if gte mso 9]></td><td><![endif]-->
								<!-- START col 2 -->
								<table border="0" cellpadding="0" cellspacing="0" width="320" align="right" class="fluidContainer">
									<tr>
										<td style="padding: 0 30px 30px 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!--start img-->
												<tr>
													<td style="padding: 0 0 15px 0;">
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/260-2.jpg" width="260" height="170" border="0" style="display: block;" class="fluidImg" alt="Lorem ipsum dolor">
													</td>
												</tr>
												<!-- //END img -->
												<!-- start heading "h2 like" -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:20px;font-weight:bold;line-height:135%;color:#333333;text-align:center;">
														Quis autem vel eum iure consequatur
													</td>
												</tr>
												<!-- //END heading "h2 like" -->
												<!-- start paragraph -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:15px;line-height:135%;color:#666666;text-align:center;">
														Vel illum qui dolorem eum fugiat quo voluptas nulla pariatur ?
													</td>
												</tr>
												<!-- //END paragraph -->
												<!-- start button default -->
												<tr>
													<td>
														<table width="100%" border="0" cellspacing="0" cellpadding="0">
															<tr>
																<td>
																	<table border="0" cellspacing="0" cellpadding="0" align="center">
																		<tr>
																			<td bgcolor="#E03627" style="padding:10px 20px 10px 20px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px" align="center"><a href="http://mail.mindbaz.com" target="_blank" style="font-size:15px;font-family:Arial, Helvetica, sans-serif;font-weight:normal;color:#FFFFFF;text-decoration:none;display:block;">Lorem ipsum</a>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
													</td>
												</tr>
												<!-- //END button default -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 2 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START wrapper -->
					<table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="320" align="left" class="fluidContainer">
									<tr>
										<td style="padding: 0 30px 30px 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!--start img-->
												<tr>
													<td style="padding: 0 0 15px 0;">
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/260-3.jpg" width="260" height="170" border="0" style="display: block;" class="fluidImg" alt="Lorem ipsum dolor">
													</td>
												</tr>
												<!-- //END img -->
												<!-- start heading "h2 like" -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:20px;font-weight:bold;line-height:135%;color:#333333;text-align:center;">
														Sed ut perspiciatis
													</td>
												</tr>
												<!-- //END heading "h2 like" -->
												<!-- start paragraph -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:15px;line-height:135%;color:#666666;text-align:center;">
														Lorem ipsum dolor sit amet, consectetur adipiscing elit.
													</td>
												</tr>
												<!-- //END paragraph -->
												<!-- start button default -->
												<tr>
													<td>
														<table width="100%" border="0" cellspacing="0" cellpadding="0">
															<tr>
																<td>
																	<table border="0" cellspacing="0" cellpadding="0" align="center">
																		<tr>
																			<td bgcolor="#E03627" style="padding:10px 20px 10px 20px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px" align="center"><a href="http://mail.mindbaz.com" target="_blank" style="font-size:15px;font-family:Arial, Helvetica, sans-serif;font-weight:normal;color:#FFFFFF;text-decoration:none;display:block;">Perspiciatis</a>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
													</td>
												</tr>
												<!-- //END button default -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
								<!--[if gte mso 9]></td><td><![endif]-->
								<!-- START col 2 -->
								<table border="0" cellpadding="0" cellspacing="0" width="320" align="right" class="fluidContainer">
									<tr>
										<td style="padding: 0 30px 30px 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!--start img-->
												<tr>
													<td style="padding: 0 0 15px 0;">
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/260-4.jpg" width="260" height="170" border="0" style="display: block;" class="fluidImg" alt="Lorem ipsum dolor">
													</td>
												</tr>
												<!-- //END img -->
												<!-- start heading "h2 like" -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:20px;font-weight:bold;line-height:135%;color:#333333;text-align:center;">
														Quis autem vel eum iure consequatur
													</td>
												</tr>
												<!-- //END heading "h2 like" -->
												<!-- start paragraph -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:15px;line-height:135%;color:#666666;text-align:center;">
														Vel illum qui dolorem eum fugiat quo voluptas nulla pariatur ?
													</td>
												</tr>
												<!-- //END paragraph -->
												<!-- start button default -->
												<tr>
													<td>
														<table width="100%" border="0" cellspacing="0" cellpadding="0">
															<tr>
																<td>
																	<table border="0" cellspacing="0" cellpadding="0" align="center">
																		<tr>
																			<td bgcolor="#E03627" style="padding:10px 20px 10px 20px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px" align="center"><a href="http://mail.mindbaz.com" target="_blank" style="font-size:15px;font-family:Arial, Helvetica, sans-serif;font-weight:normal;color:#FFFFFF;text-decoration:none;display:block;">Lorem ipsum</a>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
													</td>
												</tr>
												<!-- //END button default -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 2 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START wrapper -->
					<table bgcolor="#E03627" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="400" align="left" class="fluidContainer">
									<tr>
										<td style="padding: 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:20px;line-height:135%;color:#ffffff;text-align:left;text-transform:uppercase;">
														Consectetur adipiscing elit !
													</td>
												</tr>
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:15px;line-height:135%;color:#ffffff;text-align:left;">
														Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
													</td>
												</tr>
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
								<!--[if gte mso 9]></td><td><![endif]-->
								<!-- START col 2 -->
								<table border="0" cellpadding="0" cellspacing="0" width="240" align="right" class="fluidContainer">
									<tr>
										<td style="padding: 60px 30px 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!-- start button default -->
												<tr>
													<td>
														<table width="100%" border="0" cellspacing="0" cellpadding="0">
															<tr>
																<td>
																	<table border="0" cellspacing="0" cellpadding="0" align="center">
																		<tr>
																			<td bgcolor="#FFFFFF" style="padding:10px 20px 10px 20px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px" align="center"><a href="http://mail.mindbaz.com" target="_blank" style="font-size:18px;font-family:Arial, Helvetica, sans-serif;font-weight:normal;color:#E03627;text-decoration:none;display:block;">Lorem ipsum</a>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
													</td>
												</tr>
												<!-- //END button default -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 2 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START wrapper -->
					<table bgcolor="#F4F4F4" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="100%" align="right" class="fluidContainer">
									<tr>
										<td style="padding: 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!-- start social buttons center -->
												<tr>
													<td>
														<table width="100%" border="0" cellspacing="0" cellpadding="0">
															<tr>
																<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:18px;line-height:135%;color:#666666;text-align:center;">
																	Lorem ipsum dolor sit amet
																</td>
															</tr>
															<tr>
																<td>
																	<table border="0" cellspacing="0" cellpadding="0" align="center">
																		<tr>
																			<td style="padding: 0 5px;">
																				<a href="#Facebook" target="_blank">
																					<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/facebook.jpg" width="32" height="32" style="display:block;" border="0" alt="Follow us on Facebook" />
																				</a>
																			</td>
																			<td style="padding: 0 5px;">
																				<a href="#Twitter" target="_blank">
																					<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/twitter.jpg" width="32" height="32" style="display:block;" border="0" alt="Follow us on Twitter" />
																				</a>
																			</td>
																			<td style="padding: 0 5px;">
																				<a href="#Googleplus" target="_blank">
																					<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/google-plus.jpg" width="32" height="32" style="display:block;" border="0" alt="Follow us on Googleplus" />
																				</a>
																			</td>
																			<td style="padding: 0 5px;">
																				<a href="#Instagram" target="_blank">
																					<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/instagram.jpg" width="32" height="32" style="display:block;" border="0" alt="Follow us on Instagram" />
																				</a>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
													</td>
												</tr>
												<!-- //END social button center -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START FOOTER -->
					<table bgcolor="#F4F4F4" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col -->
								<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
									<tr>
										<td style="padding: 30px;">
											<!-- START footer txt -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;font-size:12px;line-height:135%;color:#999999;text-align:center;">
														Sed ut perspiciatis unde omnis iste duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. <a href="#" style="color:#999999;">Lorem ipsum natus error</a>
													</td>
												</tr>
											</table>
											<!-- //END footer txt -->
										</td>
									</tr>
								</table>
								<!-- //END col -->
							</td>
						</tr>
					</table>
					<!-- //END FOOTER -->
				</td>
				<!-- //END main td -->
			</tr>
		</table>
		<!-- //END  main table -->

	</center>
</body>

</html>
`;
