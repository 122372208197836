import template1 from "../../assets/components/Templates/html/template1";
import template2 from "../../assets/components/Templates/html/template2";
import template3 from "../../assets/components/Templates/html/template3";
import template4 from "../../assets/components/Templates/html/template4";
import template5 from "../../assets/components/Templates/html/template5";
import template6 from "../../assets/components/Templates/html/template6";
import preview1 from "../../assets/components/Templates/previews/preview1.png";
import preview2 from "../../assets/components/Templates/previews/preview2.png";
import preview3 from "../../assets/components/Templates/previews/preview3.png";
import preview4 from "../../assets/components/Templates/previews/preview4.png";
import preview5 from "../../assets/components/Templates/previews/preview5.png";
import preview6 from "../../assets/components/Templates/previews/preview6.png";

export default [
  {
    title: "The Company",
    content: template1,
    preview: preview1,
  },
  {
    title: "The Newsletter",
    content: template2,
    preview: preview2,
  },
  {
    title: "The Website #1",
    content: template3,
    preview: preview3,
  },
  {
    title: "The Website #2",
    content: template4,
    preview: preview4,
  },
  {
    title: "The Shop",
    content: template5,
    preview: preview5,
  },
  {
    title: "The Good Deal",
    content: template6,
    preview: preview6,
  },
];
