import i18n from "../../../translations";

export default [
  {
    content: i18n.t("tutorial.beginner.step_1"),
  },
  {
    selector: 'span[title="Import template"]',
    content: i18n.t("tutorial.beginner.step_2"),
  },
  {
    selector: 'span[title="Open Blocks"]',
    content: i18n.t("tutorial.beginner.step_3"),
  },
  {
    selector: 'span[title="Templates"]',
    content: i18n.t("tutorial.beginner.step_4"),
  },
];
