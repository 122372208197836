export default `
<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
<html xmlns="http://www.w3.org/1999/xhtml">

<head>
	<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
	<meta name="viewport" content="width=device-width, initial-scale=1.0">
	<meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
	<title>Fullbaz - Promo template</title>
	<style>
		html { background-color: #F4F4F4; margin: 0; padding: 0; }
	body, .selectors-mainTable, .selectors-mainTd { height: 100% !important; margin: 0; padding: 0; width: 100% !important; font-family: Arial, Helvetica, sans-serif; }
	table, table td { border-collapse: collapse; }
	table[class="mainTable"] { width: 100% !important; margin: auto; max-width: 620px !important; color: #666666; }
	img, a img { border: 0; outline: none; text-decoration: none; height: auto; line-height: 100%; }
	.ReadMsgBody { width: 100%; }
	.ExternalClass { width: 100%; }
	.ExternalClass, .ExternalClass p, .ExternalClass span, .ExternalClass font, .ExternalClass td, .ExternalClass div { line-height: 100%; }
	table, td { mso-table-lspace: 0pt; mso-table-rspace: 0pt; }
	#outlook a { padding: 0; }
	img { -ms-interpolation-mode: bicubic; display: block; outline: none; text-decoration: none; }
	body, table, td, p, a, li, blockquote { -ms-text-size-adjust: 100%; -webkit-text-size-adjust: 100%; }
	@media only screen and (max-width: 480px) { body { width: 100% !important; min-width: 100% !important; }
	  table[class="fluidContainer"] { width: 100% !important; }
	  table[class="fluidHalfContainer"] { width: 50% !important; }
	  img[class="fluidImg"] { width: 100% !important; height: auto !important; }
	  *[class="mbzShowMobile"] { max-height: none !important; max-width: none !important; opacity: 1 !important; overflow: auto !important; }
	  table[class="mbzShowMobile"] { display: table !important; }
	  td[class="mbzShowMobile"] { display: table-cell !important; } }
	</style>
</head>

<body bgcolor="#F4F4F4" leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0">
	<!-- center layout -->
	<center style="background-color:#F4F4F4;">

		<!-- START main table -->
		<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" class="mainTable" style="table-layout: fixed;max-width:100% !important;width: 100% !important;min-width: 100% !important;">
			<tr>
				<!-- START main td -->
				<td align="center" valign="top" class="mainTd" style="padding: 30px 0 30px 0;">

					<!-- START HEADER -->
					<table bgcolor="#F4F4F4" border="0" cellpadding="0" cellspacing="0" width="620" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col -->
								<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
									<tr>
										<td style="padding: 0 30px 15px 30px;">
											<!-- START header txt -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;font-size:12px;line-height:135%;color:#999999;text-align:center;">
														Sed ut perspiciatis unde omnis iste <a href="#" style="color:#999999;">natus error</a>
													</td>
												</tr>
											</table>
											<!-- //END header txt -->
										</td>
									</tr>
								</table>
								<!-- //END col -->
							</td>
						</tr>
					</table>
					<!-- //END HEADER -->

					<!-- START wrapper -->
					<table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="100%" align="left" class="fluidContainer">
									<tr>
										<td style="padding: 20px">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<tr>
													<td style="text-align:center">
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/logo4.jpg" alt="Your company logo" style="display:inline;">
													</td>
												</tr>
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START wrapper -->
					<table bgcolor="#925e82" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="340" align="left" class="fluidContainer">
									<tr>
										<td style="padding:0;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<tr>
													<td>
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/phone340.jpg" alt="Lorem ipsum" class="fluidImg" />
													</td>
												</tr>
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
								<!--[if gte mso 9]></td><td><![endif]-->
								<!-- START col 2 -->
								<table border="0" cellpadding="0" cellspacing="0" width="300" align="right" class="fluidContainer">
									<tr>
										<td style="padding: 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<tr>
													<td style="font-weight:bold;font-family:Trebuchet MS, sans-serif;padding: 60px 0 20px;font-size:34px;line-height:135%;color:#FFFFFF;text-align:center;">
														LOREM IPSUM DOLOR !
													</td>
												</tr>
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 80px 0;font-size:15px;line-height:135%;color:#FFFFFF;text-align:center;">
														Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor. 
													</td>
												</tr>
												<!-- start button default -->
												<tr>
													<td>
														<table width="100%" border="0" cellspacing="0" cellpadding="0">
															<tr>
																<td>
																	<table border="0" cellspacing="0" cellpadding="0" align="center" width="100%">
																		<tr>
																			<td bgcolor="#FFFFFF" style="padding:10px 20px 10px 20px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px" align="center"><a href="http://mail.mindbaz.com" target="_blank" style="font-size:15px;font-family:Arial, Helvetica, sans-serif;font-weight:normal;color:#925e82;text-decoration:none;display:block;">Lorem ipsum</a>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
														</table>
													</td>
												</tr>
												<!-- //END button default -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 2 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START wrapper -->
					<table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="100%" align="left" class="fluidContainer">
									<tr>
										<td style="padding: 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<tr>
													<td style="font-weight:bold;font-family:Trebuchet MS, sans-serif;padding: 0 0 20px;font-size:28px;line-height:135%;color:#925e82;text-align:left;">
														Natus error sit
													</td>
												</tr>
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-size:15px;line-height:135%;color:#666666;text-align:left;">
														Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. 
													</td>
												</tr>
											</table>
											<!-- //END  content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->



					<!-- START wrapper -->
					<table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col 1 -->
								<table border="0" cellpadding="0" cellspacing="0" width="213" align="left" class="fluidContainer">
									<tr>
										<td style="padding: 0 30px 30px 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!--start img-->
												<tr>
													<td style="padding: 0 0 15px 0;">
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/icon1.jpg" width="153" height="77" border="0" style="display: block;" class="fluidImg" alt="Lorem ipsum dolor">
													</td>
												</tr>
												<!-- //END img -->
												<!-- start heading "h3 like" -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-weight:bold;font-size:16px;line-height:135%;color:#925E82;text-align:center;">
														Dolore eu fugiat
													</td>
												</tr>
												<!-- //END heading "h3 like" -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 1 -->
								<!--[if gte mso 9]></td><td><![endif]-->
								<!-- START col 2 -->
								<table border="0" cellpadding="0" cellspacing="0" width="213" align="left" class="fluidContainer">
									<tr>
										<td style="padding: 0 30px 30px 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!--start img-->
												<tr>
													<td style="padding: 0 0 15px 0;">
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/icon2.jpg" width="153" height="77" border="0" style="display: block;" class="fluidImg" alt="Lorem ipsum dolor">
													</td>
												</tr>
												<!-- //END img -->
												<!-- start heading "h3 like" -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-weight:bold;font-size:16px;line-height:135%;color:#925E82;text-align:center;">
														Consectetur adipiscing
													</td>
												</tr>
												<!-- //END heading "h3 like" -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 2 -->
								<!--[if gte mso 9]></td><td><![endif]-->
								<!-- START col 3 -->
								<table border="0" cellpadding="0" cellspacing="0" width="213" align="right" class="fluidContainer">
									<tr>
										<td style="padding: 0 30px 30px 30px;">
											<!-- start content -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<!--start img-->
												<tr>
													<td style="padding: 0 0 15px 0;">
														<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/icon3.jpg" width="153" height="77" border="0" style="display: block;" class="fluidImg" alt="Lorem ipsum dolor">
													</td>
												</tr>
												<!-- //END img -->
												<!-- start heading "h3 like" -->
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;padding: 0 0 15px 0;font-weight:bold;font-size:16px;line-height:135%;color:#925E82;text-align:center;">
														Sit amet
													</td>
												</tr>
												<!-- //END heading "h3 like" -->
											</table>
											<!-- //END content -->
										</td>
									</tr>
								</table>
								<!-- //END col 3 -->
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START wrapper -->
					<table bgcolor="#565656" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<table border="0" cellpadding="0" cellspacing="0" width="90%" align="center" class="fluidContainer">
									<tr>
										<td style="padding: 30px 0;">
											<!-- START col 1 -->
											<table border="0" cellpadding="0" cellspacing="0" width="70%" align="left" class="fluidContainer">
												<tr>
													<td style="padding: 0 10px;">
														<!-- start content -->
														<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
															<!-- start input-like -->
															<tr>
																<td>
																	<table width="100%" border="0" cellspacing="0" cellpadding="0">
																		<tr>
																			<td>
																				<table border="0" cellspacing="0" cellpadding="0" width="100%" align="center">
																					<tr>
																						<td bgcolor="#ffffff" style="padding:10px 20px 10px 20px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px" align="center"><a href="http://mail.mindbaz.com" target="_blank" style="font-size:15px;font-family:Trebuchet MS, Arial, sans-serif;font-weight:normal;color:#999999;text-decoration:none;display:block;text-align:left;">your-email@domain.com</a>
																						</td>
																					</tr>
																				</table>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
															<!-- //END input-like -->
														</table>
														<!-- //END content -->
													</td>
												</tr>
											</table>
											<!-- //END col 1 -->
											<!--[if gte mso 9]></td><td><![endif]-->
											<!-- START col 2 -->
											<table border="0" cellpadding="0" cellspacing="0" width="30%" align="right" class="fluidContainer">
												<tr>
													<td style="padding:0 10px;">
														<!-- start content -->
														<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
															<!-- start button default -->
															<tr>
																<td>
																	<table width="100%" border="0" cellspacing="0" cellpadding="0">
																		<tr>
																			<td>
																				<table border="0" cellspacing="0" cellpadding="0" width="100%" align="center">
																					<tr>
																						<td bgcolor="#b3709e" style="padding:10px 20px 10px 20px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px" align="center"><a href="http://mail.mindbaz.com" target="_blank" style="font-size:15px;font-family:Arial, Helvetica, sans-serif;font-weight:normal;color:#FFFFFF;text-decoration:none;display:block;">Je m'inscris</a>
																						</td>
																					</tr>
																				</table>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
															<!-- //END button default -->
														</table>
														<!-- //END content -->
													</td>
												</tr>
											</table>
											<!-- //END col 2 -->
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START wrapper -->
					<table bgcolor="#FFFFFF" border="0" cellpadding="0" cellspacing="0" width="640" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" class="fluidContainer">
									<tr>
										<td style="padding: 30px 0;">
											<!-- START col 1 -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="left" class="fluidContainer">
												<tr>
													<td style="padding: 0 10px;">
														<!-- start content -->
														<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
															<!-- start social buttons center -->
															<tr>
																<td>
																	<table width="100%" border="0" cellspacing="0" cellpadding="0">
																		<tr>
																			<td>
																				<table border="0" cellspacing="0" cellpadding="0" align="center">
																					<tr>
																						<td style="padding: 0 5px;">
																							<a href="#Facebook" target="_blank">
																								<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/facebook.jpg" width="32" height="32" style="display:block;" border="0" alt="Follow us on Facebook" />
																							</a>
																						</td>
																						<td style="padding: 0 5px;">
																							<a href="#Twitter" target="_blank">
																								<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/twitter.jpg" width="32" height="32" style="display:block;" border="0" alt="Follow us on Twitter" />
																							</a>
																						</td>
																						<td style="padding: 0 5px;">
																							<a href="#Googleplus" target="_blank">
																								<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/google-plus.jpg" width="32" height="32" style="display:block;" border="0" alt="Follow us on Googleplus" />
																							</a>
																						</td>
																						<td style="padding: 0 5px;">
																							<a href="#Instagram" target="_blank">
																								<img src="https://www.mindbaz.com/wp-content/uploads/2020/07/instagram.jpg" width="32" height="32" style="display:block;" border="0" alt="Follow us on Instagram" />
																							</a>
																						</td>
																					</tr>
																				</table>
																			</td>
																		</tr>
																	</table>
																</td>
															</tr>
															<!-- //END social button center -->
														</table>
														<!-- //END content -->
													</td>
												</tr>
											</table>
											<!-- //END col 1 -->
										</td>
									</tr>
								</table>
							</td>
						</tr>
					</table>
					<!-- //END wrapper -->

					<!-- START FOOTER -->
					<table bgcolor="#F4F4F4" border="0" cellpadding="0" cellspacing="0" width="620" align="center" class="fluidContainer">
						<tr>
							<td align="center" valign="top">
								<!-- START col -->
								<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
									<tr>
										<td style="padding: 30px;">
											<!-- START footer txt -->
											<table border="0" cellpadding="0" cellspacing="0" width="100%" align="center">
												<tr>
													<td style="font-family:Arial, Helvetica, sans-serif;font-size:12px;line-height:135%;color:#999999;text-align:center;">
														Sed ut perspiciatis unde omnis iste duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. <a href="#" style="color:#999999;">Lorem ipsum natus error</a>
													</td>
												</tr>
											</table>
											<!-- //END footer txt -->
										</td>
									</tr>
								</table>
								<!-- //END col -->
							</td>
						</tr>
					</table>
					<!-- //END FOOTER -->
				</td>
				<!-- //END main td -->
			</tr>
		</table>
		<!-- //END  main table -->

	</center>
</body>

</html>
`;
